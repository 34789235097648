var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.app.accessSySafra)?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('DataFilter',{attrs:{"filterProps":{ includeSearch: true, items: [] },"paginate":true,"items":_vm.backups},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mr-1",attrs:{"label":"Data Inicial","type":"date","outlined":"","dense":"","hide-details":""},model:{value:(_vm.date.start),callback:function ($$v) {_vm.$set(_vm.date, "start", $$v)},expression:"date.start"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mr-1",attrs:{"label":"Data Final","type":"date","outlined":"","dense":"","hide-details":""},model:{value:(_vm.date.end),callback:function ($$v) {_vm.$set(_vm.date, "end", $$v)},expression:"date.end"}})],1),_c('v-btn',{attrs:{"color":"primary","icon":"","outlined":""},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi mdi-refresh")])],1)]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","single-expand":"","item-key":"id","show-expand":"","headers":_vm.headers,"items":items,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'ERRO')?_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Erro")]):(item.status === 'EM_EXECUCAO')?_c('v-chip',{attrs:{"color":"warning"}},[_vm._v("Em andamento")]):(item.status === 'CONCLUIDO')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Concluido")]):_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.datahorainicio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.datahorainicio)))])]}},{key:"item.datahorafim",fn:function(ref){
var item = ref.item;
return [(item.datahorafim)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.datahorafim)))]):_c('span',[_vm._v("-")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_vm._v("Arquivos")])]),_c('v-expansion-panel-content',[(item.arquivos.length > 0)?_c('v-data-table',{staticClass:"my-2",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.arquivosHeaders,"items":item.arquivos},scopedSlots:_vm._u([{key:"item.datahora",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.datahora)))])]}},{key:"item.tamanho",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertSize(item.tamanho)))])]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_c('span',[_vm._v("Nenhum arquivo encontrado")])])]},proxy:true}],null,true)}):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_vm._v("Logs")])]),_c('v-expansion-panel-content',[(item.logs.length > 0)?_c('v-data-table',{staticClass:"my-2",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.logsHearders,"items":item.logs,"sort-by":['datahora', 'id'],"sort-desc":[true, true]},scopedSlots:_vm._u([{key:"item.datahora",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.datahora)))])]}},{key:"item.tipobackup",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tipobackup === 'FULL' ? 'Completo' : 'Incremental'))])]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_c('span',[_vm._v("Nenhum log encontrado")])])]},proxy:true}],null,true)}):_vm._e()],1)],1)],1)],1)]}}],null,true)})]}}],null,false,2803636504)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
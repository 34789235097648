<template lang="pug">
div(v-if="$store.state.app.accessSySafra")
  v-container(fluid)
    DataFilter(
      :filterProps="{ includeSearch: true, items: [] }"
      :paginate="true"
      :items="backups"
    )
      template(v-slot:actions)
        v-col(cols="12" md="2")
          v-text-field(
            class="mr-1"
            v-model="date.start"
            label="Data Inicial"
            type="date"
            outlined
            dense
            hide-details
          )
        v-col(cols="12" md="2")

          v-text-field(
            class="mr-1"
            v-model="date.end"
            label="Data Final"
            type="date"
            outlined
            dense
            hide-details
          )
        v-btn(color="primary" @click="getData" icon outlined)
          v-icon mdi mdi-refresh

      template(v-slot:default="{ items }")
        v-data-table(
          dense
          disable-pagination
          hide-default-footer
          single-expand
          item-key="id"
          show-expand
          :headers="headers"
          :items="items"
          :expanded.sync="expanded"
        )
          template(v-slot:item.status="{ item }")
            v-chip(v-if="item.status === 'ERRO'" color="error") Erro
            v-chip(v-else-if="item.status === 'EM_EXECUCAO'" color="warning") Em andamento
            v-chip(v-else-if="item.status === 'CONCLUIDO'" color="success") Concluido
            v-chip(v-else color="grey") {{ item.status }}

          template(v-slot:item.datahorainicio="{ item }")
            span {{ formatDate(item.datahorainicio) }}
          template(v-slot:item.datahorafim="{ item }")
            span(v-if="item.datahorafim") {{ formatDate(item.datahorafim) }}
            span(v-else) -
          template(v-slot:expanded-item="{ headers, item }")
            td(:colspan="headers.length")
              v-expansion-panels
                v-expansion-panel
                  v-expansion-panel-header
                    span Arquivos
                  v-expansion-panel-content
                    v-data-table(
                      class="my-2"
                      v-if="item.arquivos.length > 0"
                      dense
                      disable-pagination
                      hide-default-footer
                      :headers="arquivosHeaders"
                      :items="item.arquivos"
                    )
                      template(v-slot:item.datahora="{ item }")
                        span {{ formatDate(item.datahora) }}
                      template(v-slot:item.tamanho="{ item }")
                        span {{ convertSize(item.tamanho) }}
                      template(v-slot:no-data)
                        td(colspan="6" class="text-center")
                          span Nenhum arquivo encontrado

                v-expansion-panel
                  v-expansion-panel-header
                    span Logs
                  v-expansion-panel-content
                    v-data-table(
                      class="my-2"
                      v-if="item.logs.length > 0"
                      dense
                      disable-pagination
                      hide-default-footer
                      :headers="logsHearders"
                      :items="item.logs"
                      :sort-by="['datahora', 'id']"
                      :sort-desc="[true, true]"
                    )
                      template(v-slot:item.datahora="{ item }")
                        span {{ formatDate(item.datahora) }}
                      template(v-slot:item.tipobackup="{ item }")
                        span {{ item.tipobackup === 'FULL' ? 'Completo' : 'Incremental' }}
                      template(v-slot:no-data)
                        td(colspan="6" class="text-center")
                          span Nenhum log encontrado

</template>

<script>
import { mapMutations } from 'vuex';
import csapi from '@/api/csapi';

export default {
  data: () => ({
    menu: false,
    expanded: [],
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Status', value: 'status' },
      { text: 'Inicio', value: 'datahorainicio' },
      { text: 'Fim', value: 'datahorafim' },
      { text: '', value: 'data-table-expand' },
    ],
    logsHearders: [
      { text: 'ID', value: 'id' },
      { text: 'Tipo', value: 'tipobackup' },
      { text: 'Data e hora', value: 'datahora' },
      { text: 'Mensagem', value: 'mensagem.mensagem' },
    ],
    arquivosHeaders: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Data e hora', value: 'datahora' },
      { text: 'Nome', value: 'nome' },
      { text: 'MD5', value: 'md5', sortable: false },
      { text: 'Tamanho', value: 'tamanho' },
    ],
    backups: [],
    date: {
      start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substring(0, 10),
      end: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substring(0, 10),
    },
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),
    getData() {
      const { cliente } = this.$route.params;

      const query = ''
        .concat(`?client=${cliente}`)
        .concat(`&interval=${this.date.start},${this.date.end}`)
        .concat('&include-details=true');

      this.showLoader();

      csapi.get(`/sysafra/v1/sysbackup/item${query}`)
        .then((response) => {
          if (response.data.length > 0) this.setTitle(`Histórico de Backups - ${response.data[0].cliente.nome}`);
          this.backups = response.data;
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.$toast.error(error.response.data.message);
          console.error(error);
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
      });
    },
    convertSize(size) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (size === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)), 10);
      return `${Math.round(size / (1024 ** i), 2)} ${sizes[i]}`;
    },
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    this.setTitle(`Histórico de Backups - ${localStorage.getItem('historyClientName')}`);
    this.isControl(false);
    this.getData();
  },
};

</script>
